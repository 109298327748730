import React, {useState, useEffect, useRef} from 'react'
import './index.scss'
import Swiper from 'swiper/js/swiper'
import 'swiper/css/swiper.min.css'
import {useTranslation} from "react-i18next"
interface propsType {
  gameCharacters: {
    "desc": string,
    url: string
  }[]
}

export const Slider1: React.FC<propsType> = ({gameCharacters}) => {
  let swiperRef1 = useRef(null);
  const {t} = useTranslation();
  useEffect(() => {
    swiperRef1 = new Swiper('.games', {
      slidesPerView: 5,
      spaceBetween: 10,
      slidesPerGroup: 5,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  }, [])

  return (
    <div className="swiper-container games">
      <div className="swiper-wrapper swiper-no-swiping">
        {
          gameCharacters.map((item,index) => <div className="swiper-slide" key={'slide1'+index} >
            <div className="name" dangerouslySetInnerHTML={{__html: t(item.desc)}}></div>
            <img src={item.url} alt=""/>
          </div>)
        }
      </div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
    </div>
  )
}
