import React, { useEffect, useRef} from 'react'
import './index.scss'
import Swiper from 'swiper/js/swiper'
import 'swiper/css/swiper.min.css'
import {useTranslation} from "react-i18next"
interface propsType {
  gameCharacters: {
    "date": string,
    "desc": string,
    url: string,
    newsUrl: string
  }[],
  goToDetail:Function
}

export const Slider: React.FC<propsType> = ({gameCharacters,goToDetail}) => {
  let swiperRef = useRef(null);
  const {t} = useTranslation();
  useEffect(() => {
    swiperRef = new Swiper('.news', {
      slidesPerView: 3,
      spaceBetween: 10,
      slidesPerGroup: 3,
      // loop: true,
      // loopFillGroupWithBlank: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  }, [])

  return (
    <div className="swiper-container news">
      <div className="swiper-wrapper swiper-no-swiping">
        {
          gameCharacters.map((item,index) => <div className="swiper-slide" key={'slide'+index}>
            <div className="info">
              <img src={item.url} alt="" onClick={()=>goToDetail(index)} />
              <div className="desc" onClick={()=>goToDetail(index)}>{t(item.desc)}</div>
              <div className="date" onClick={()=>goToDetail(index)}>{t(item.date)}</div>
            </div>
            <div className="btn" onClick={()=>goToDetail(index)}>{t('Browse')}</div>
          </div>)
        }
      </div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
    </div>
  )
}
