import React, {useState, useEffect, useRef} from 'react'
import './index.scss'
import Swiper from 'swiper/js/swiper'
import 'swiper/css/swiper.min.css'
import {useTranslation} from "react-i18next"
interface propsType {
  gameCharacters: {
    "desc": string,
    url: string
  }[]
}

export const Slider3: React.FC<propsType> = ({gameCharacters}) => {
  let swiperRef1 = useRef(null);
  const {t} = useTranslation();
  useEffect(() => {
    swiperRef1 = new Swiper('.games1', {
      slidesPerView: "auto",
      spaceBetween: '4%',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
      }
    });
  }, [])

  return (
    <div className="swiper-container games1">
      <div className="swiper-wrapper">
        {
          gameCharacters.map((item,index) => <div className="swiper-slide" key={'slide3'+index}>
            <div className="name" dangerouslySetInnerHTML={{__html: t(item.desc)}}></div>
            <img src={item.url} alt=""/>
          </div>)
        }
      </div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-scrollbar"></div>
    </div>
  )
}
