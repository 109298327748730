// import "babel-polyfill";
import React, {useState, useEffect} from 'react'
import styles from './App.module.scss'
import {useTranslation} from "react-i18next"
import {Slider, Slider1,Slider2,Slider3} from './components'
const App: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const {t, i18n} = useTranslation();
  const [newsData] = useState<{
    "date": string,
    "desc": string,
    url: string,
    newsUrl: string
  }[]>([{
    date: 'game_collaboration_date',
    desc: 'game_collaboration',
    url: `${require(`./images/index/news_banner.png`)}`,
    newsUrl: `${require(`./images/news/news1_icon.png`)}`
  }, {
    date: 'HarmonyOS_Ecosystem_date',
    desc: 'HarmonyOS_Ecosystem',
    url: `${require(`./images/index/news_1.png`)}`,
    newsUrl: `${require(`./images/news/news2_icon.png`)}`
  }, {
    date: 'Divine_warrior_Feng_Xian_date',
    desc: 'Divine_warrior_Feng_Xian',
    url: `${require(`./images/index/news_2.png`)}`,
    newsUrl: `${require(`./images/news/news3_icon.png`)}`
  }, {
    date: 'Divine_warrior_Sun_Shangxiang_date',
    desc: 'Divine_warrior_Sun_Shangxiang',
    url: `${require(`./images/index/news_3.png`)}`,
    newsUrl: `${require(`./images/news/news4_icon.png`)}`
  }])
  const [gamesData] = useState<{
    "desc": string,
    url: string
  }[]>([{
    desc: 'Eastern_Saga',
    url: `${require(`./images/index/game_1.png`)}`
  }, {
    desc: 'Blossoms',
    url: `${require(`./images/index/game_2.png`)}`
  }, {
    desc: 'Star_havoc',
    url: `${require(`./images/index/game_3.png`)}`
  }, {
    desc: 'Eastern_Saga2',
    url: `${require(`./images/index/game_4.png`)}`
  },
    {
      desc: 'Duoduo_Play',
      url: `${require(`./images/index/game_5.png`)}`
    }, {
      desc: 'MIR',
      url: `${require(`./images/index/game_6.png`)}`
    }, {
      desc: 'Sword_Song',
      url: `${require(`./images/index/game_7.png`)}`
    }, {
      desc: 'Clone_Crazy_Alpha',
      url: `${require(`./images/index/game_8.png`)}`
    }])
  const [aboutData] = useState<{
    "title": string,
    "desc": string,
    url?: string
  }[]>([{
    title: 'Vision',
    desc: 'Vision_desc',
    // url: `${require(`./images/about/icon1.png`)}`
  }, {
    title: 'Mission',
    desc: 'Mission_desc',
    // url: `${require(`./images/about/icon2.png`)}`
  }, {
    title: 'Philosophy',
    desc: 'Philosophy_desc',
    // url: `${require(`./images/about/icon3.png`)}`
  }])
  const [tabIdx, setTabIdx] = useState<number>(0)
  const [isShowLangOptions, setIsShowLangOptions] = useState<boolean>(false)
  const [langOptions] = useState<{ [key: string]: string }>({
    en: "English",
    zh: "简体中文",
  })
  const [detailIdx, setDetailIdx] = useState<number>(-1)
  const [showTabSelect, setShowTabSelect] = useState<boolean>(false)
  const [uiType]= useState<number>(1)
  useEffect(() => {
    document.title = i18n.language == 'en' ? '9palace games' : '九宫游戏'
  }, []) //如果第二个参数赋值是个空数组（相当于模拟类函数的componentDidMount），那么仅会在第一个挂载UI时调用
  useEffect(() => {
    window.scroll(0,0)
  }, [tabIdx]) //如果第二个参数赋值是个空数组（相当于模拟类函数的componentDidMount），那么仅会在第一个挂载UI时调用
  useEffect(() => {
    setShowTabSelect(false)
    setIsShowLangOptions(false)
    detailIdx>=0&&window.scroll(0,0)
  }, [detailIdx]) //如果第二个参数赋值是个空数组（相当于模拟类函数的componentDidMount），那么仅会在第一个挂载UI时调用
  function isMobileDevice(screenWidthVal=768) {
    const userAgent = navigator.userAgent;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const isMobile = /Mobile/i.test(userAgent) || screenWidth < screenWidthVal;
    return isMobile
  }

  return (
    <div className={`${styles.NINTHPALACE} ${isMobileDevice() ? styles.mobile : ''} ${styles["tab" + tabIdx]}`}>
      <div
        className={`${styles.bg} ${isMobileDevice() ? styles.mobile : ''} ${styles["tab" + tabIdx]}`}
      >
        <div className={`${styles.tabs}  ${styles['ui'+uiType]}  ${isMobileDevice() ? styles.mobile : ''}`}>
          <div className={`${styles.tabs_info} ${styles['ui'+uiType]}`}>
            <div className={styles.item}>
              <img src={require(`./images/index/logo_${i18n.language}.png`)} alt="" onClick={() => {
                setDetailIdx(-1)
                setIsShowLangOptions(false)
                setShowTabSelect(false)
                setTabIdx(0)
                window.scroll(0, 0)
              }}/>
            </div>
            <div className={`${styles.item} ${styles.tab_who_we_are} ${styles[i18n.language]}`}>
              <div className={`${styles.title} ${tabIdx==1?styles.selected:''}`} onClick={() => {
                setDetailIdx(-1)
                setIsShowLangOptions(false)
                setTabIdx(1)
                window.scroll(0, 0)
              }}>{t("who_we_are").toUpperCase()}</div>
            </div>
            <div className={`${styles.item} ${styles.tab_news}`}>
              <div className={`${styles.title} ${tabIdx==2?styles.selected:''}`} onClick={() => {
                setDetailIdx(-1)
                setIsShowLangOptions(false)
                setTabIdx(2)
                window.scroll(0, 0)
              }}>{t("news").toUpperCase()}</div>
            </div>
            <div className={`${styles.item} ${styles.changeTab} ${styles['tabs'+tabIdx]} ${styles[i18n.language]}`} onClick={()=>{
              setIsShowLangOptions(false)
              if(![1,2].includes(tabIdx)){
                setTabIdx(0)
              }
              setShowTabSelect(!showTabSelect)
            }}>
              {[t("Discover"),t("who_we_are"),t("news")][(![1,2].includes(tabIdx)?0:tabIdx)]}
              <img style={{transform:`rotate(${showTabSelect?270:90}deg)`}} src={require(`./images/index/arrow.png`)} alt="" />
            </div>
          </div>
          <div className={`${styles.item} ${styles.changeLanguage} ${styles['ui'+uiType]}`}>
            <img src={require(`./images/index/language_icon.png`)} alt="" onClick={() => {
              setIsShowLangOptions(!isShowLangOptions)
              setShowTabSelect(false)
            }}/>
            {
              isShowLangOptions && <div className={styles.lang_options}>
                {
                  Object.keys(langOptions).map(key => <div
                    className={`${styles.item} ${i18n.language == key ? styles.active : ''}`} onClick={() => {
                    localStorage.setItem('NB_LANG', key);
                    i18n.changeLanguage(key)
                    setIsShowLangOptions(false)
                    document.title = i18n.language == 'en' ? '9palace games' : '九宫游戏'
                  }}>{langOptions[key]}</div>)
                }
              </div>
            }
          </div>
          {
            showTabSelect&&<div className={styles.tab_options}>
              {
                [t("who_we_are"),t("news")].map((item,index)=><div className={`${styles.tab_options_item} ${(![1,2].includes(tabIdx)?0:tabIdx)-1==index?styles.light:''}`} onClick={()=>{
                  setTabIdx(index+1)
                  setShowTabSelect(false)
                  setDetailIdx(-1)
                }}>{item}</div>)
              }
            </div>
          }
        </div>
        <div className={`${styles['act_container']} ${isMobileDevice() ? styles.mobile : ''} ${styles["tab" + tabIdx]}`}>
          {
            tabIdx == 0 &&detailIdx<0&& <>
              <div className={styles.img_bg}>
              </div>
              <div className={`${styles.sec1}  ${isMobileDevice() ? styles.mobile : ''}`}>
                <div className={styles.title_info}>
                  <img src={require(`./images/index/news_icon_${i18n.language}.png`)} alt=""
                       className={`${styles.title} ${styles[i18n.language]} ${isMobileDevice() ? styles.mobile : ''}`}/>
                  {/*<div className={styles.select_area}>*/}
                    {/*<div className={`${styles.prev} ${newsActive?styles.active:''}`} onClick={()=>{*/}
                      {/*if(newsActive){*/}
                        {/*// @ts-ignore*/}
                        {/*document.querySelector('#newList').scroll(0,0)*/}
                        {/*setNewsActive(false)*/}
                      {/*}*/}
                    {/*}*/}
                    {/*}></div>*/}
                    {/*<div className={`${styles.next} ${!newsActive?styles.active:''}`} onClick={()=>{*/}
                      {/*if(!newsActive){*/}
                        {/*// @ts-ignore*/}
                        {/*document.querySelector('#newList').scroll(document.querySelector('#newList').offsetWidth,0)*/}
                        {/*setNewsActive(true)*/}
                      {/*}*/}
                    {/*}*/}
                    {/*}></div>*/}
                  {/*</div>*/}
                </div>
                <div id="banner_list" className={`${styles.banner_list} ${isMobileDevice() ? styles.mobile : ''}`}>
                  <Slider  gameCharacters={newsData} goToDetail={val=>setDetailIdx(val)}></Slider>
                  <Slider2 gameCharacters={newsData} goToDetail={val=>setDetailIdx(val)}></Slider2>
                  {/*<div id="newList" className={`${styles.list} ${isMobileDevice() ? styles.mobile : ''}`}>*/}
                    {/*{*/}
                      {/*newsData.map((item,index) => <div className={styles.item}>*/}
                        {/*<div className={styles.info}>*/}
                          {/*<img src={item.url} alt="" onClick={() => {*/}
                            {/*setDetailIdx(index)*/}
                          {/*}*/}
                          {/*}/>*/}
                          {/*<div className={styles.desc} onClick={() => {*/}
                            {/*setDetailIdx(index)*/}
                          {/*}*/}
                          {/*}>{t(item.desc)}</div>*/}
                          {/*<div className={styles.date} onClick={() => {*/}
                            {/*setDetailIdx(index)*/}
                          {/*}*/}
                          {/*}>{t(item.date)}</div>*/}
                        {/*</div>*/}
                        {/*<div className={styles.btn} onClick={() => {*/}
                          {/*setDetailIdx(index)*/}
                        {/*}*/}
                        {/*}>{t('Browse')}</div>*/}
                      {/*</div>)*/}
                    {/*}*/}
                  {/*</div>*/}
                </div>
              </div>
              <div className={`${styles.sec2}  ${isMobileDevice() ? styles.mobile : ''}`}>
                <div className={styles.title_info}>
                  <img src={require(`./images/index/game_icon_${i18n.language}.png`)} alt=""
                       className={`${styles.title} ${styles[i18n.language]} ${isMobileDevice() ? styles.mobile : ''}`}/>
                  {/*<div className={styles.select_area}>*/}
                    {/*<div className={`${styles.prev} ${gamesactive?styles.active:''}`} onClick={()=>{*/}
                      {/*if(gamesactive){*/}
                        {/*// @ts-ignore*/}
                        {/*document.querySelector('#gamesList').scroll(0,0)*/}
                        {/*setGamesActive(false)*/}
                      {/*}*/}
                    {/*}*/}
                    {/*}></div>*/}
                    {/*<div className={`${styles.next} ${!gamesactive?styles.active:''}`} onClick={()=>{*/}
                      {/*if(!gamesactive){*/}
                        {/*// @ts-ignore*/}
                        {/*document.querySelector('#gamesList').scroll(document.querySelector('#gamesList').offsetWidth,0)*/}
                        {/*setGamesActive(true)*/}
                      {/*}*/}
                    {/*}*/}
                    {/*}></div>*/}
                  {/*</div>*/}
                </div>
                <Slider1 gameCharacters={gamesData}></Slider1>
                <Slider3 gameCharacters={gamesData}></Slider3>
                {/*<div id="gamesList" className={styles.game_list}>*/}
                  {/*{*/}
                    {/*gamesData.map(item => <div className={styles.item}>*/}
                      {/*<div className={styles.name} dangerouslySetInnerHTML={{__html: t(item.desc)}}></div>*/}
                      {/*<img src={item.url} alt=""/>*/}
                    {/*</div>)*/}
                  {/*}*/}
                {/*</div>*/}
              </div>
            </>
          }
          {
            tabIdx == 1 && <>
              <div
                className={`${styles.banner} ${styles["tab" + tabIdx]} ${isMobileDevice() ? styles.mobile : ''}`}>
                <div className={`${styles.sec3}  ${isMobileDevice() ? styles.mobile : ''}`}>
                  <img src={require(`./images/about/title_${i18n.language}.png`)} className={styles.title} alt=""/>
                  <div className={styles.desc} dangerouslySetInnerHTML={{__html: t('About_desc')}}></div>
                </div>
              </div>
              <div className={`${styles.sec4}  ${isMobileDevice() ? styles.mobile : ''}`}>
                {
                  aboutData.map(item => <div className={styles.item}>
                    <img src={require(`./images/about/${item.title}_${i18n.language}.png`)} className={styles.title} alt=""/>
                    <div className={styles.name} dangerouslySetInnerHTML={{__html: t(item.desc)}}></div>
                  </div>)
                }
              </div>
            </>
          }
          {
            tabIdx == 2 && detailIdx < 0 && <>
              <div className={`${styles.banner} ${styles["tab" + tabIdx]} ${isMobileDevice() ? styles.mobile : ''}`}>
                <img className={styles.NewsArchive} src={require(`./images/news/NewsArchive_${i18n.language}.png`)}
                     alt=""/>
              </div>
              <div className={styles.sec5}>
                <div className={styles.list}>
                  {
                    newsData.map((item, index) => <div className={styles.item} onClick={() => {
                      setDetailIdx(index)
                    }}>
                      {/*<img src={item.url} alt=""/>*/}
                      <div className={styles.info}>
                        <div className={styles.date}><i></i><span>NEWS</span>{t(item.date)}</div>
                        <div className={styles.desc}>{t(item.desc)}</div>
                      </div>
                    </div>)
                  }
                </div>
              </div>
            </>
          }
          {
            detailIdx >= 0 && <>
              <div className={`${styles.banner} ${styles["tab3"]} ${isMobileDevice() ? styles.mobile : ''}`}>
                <div className={styles.back} onClick={() => {
                  setDetailIdx(-1)
                }
                }>
                  <div className={styles.back_img}>
                    <img src={require(`./images/index/arrow.png`)} alt=""/>
                  </div>
                  {t('back')}
                </div>
                <img className={styles.NewsArchive} src={require(`./images/news/NewsArchive_${i18n.language}.png`)}
                     alt=""/>
                <div className={styles.info}><img src={require(`./images/about/title_icon.png`)} alt=""/>NINTH PALACE</div>
              </div>
              <div className={`${styles.sec6}  ${isMobileDevice() ? styles.mobile : ''}`}>
                <div className={styles.title}><i></i>{t(`detail${detailIdx}_title`)}</div>
                <div className={`${styles.desc} ${isMobileDevice() ? styles.mobile : ''} ${styles["desc"+detailIdx]}`}
                     dangerouslySetInnerHTML={{__html: `<p style="line-height: 1">${t(newsData[detailIdx].date)}</p>` + t(`detail${detailIdx}_desc`)}}></div>
              </div>
            </>
          }
        </div>
        <div className={`${styles.bottom} ${styles[i18n.language]}`}>
        </div>
      </div>
    </div>
  )
}

export default App;
