import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initReactI18next } from  'react-i18next'
import i18n from 'i18next'
import resources from './langs/lang'
i18n.use(initReactI18next).init({
  resources,
  lng:localStorage['NB_LANG'] || 'en',
  fallbackLng:'en',
  interpolation:{
    escapeValue:false
  }
})
export default function createApp() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
createApp()
