import React, {useState, useEffect, useRef} from 'react'
import './index.scss'
import Swiper from 'swiper/js/swiper'
import 'swiper/css/swiper.min.css'
import {useTranslation} from "react-i18next"
interface propsType {
  gameCharacters: {
    "date": string,
    "desc": string,
    url: string,
    newsUrl: string
  }[],
  goToDetail:Function
}

export const Slider2: React.FC<propsType> = ({gameCharacters,goToDetail}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  let swiperRef = useRef(null);
  const {t} = useTranslation();
  useEffect(() => {
    swiperRef = new Swiper('.news1', {
      slidesPerView: "auto",
      spaceBetween: '4%',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        touchEnd:function(swiper){
          setTimeout(()=>{
            // @ts-ignore
            let dom=document.querySelector('.news1 .swiper-wrapper')
            if(dom){
              // @ts-ignore
              let translate=parseFloat(dom.style.transform.replace(/translate3d\(/g,'').replace(/\)/g,'').split(',')[0])
              // @ts-ignore
              let index=Math.round(Math.abs(translate)/(document.querySelector('.news1 .swiper-slide').offsetWidth+this.width*0.04))
              setActiveIndex(index)
              // @ts-ignore
              document.querySelectorAll(`.news1 .swiper-pagination .swiper-pagination-bullet`).forEach(function(item,idx) {
                if(idx==index){
                  item.classList.add('swiper-pagination-bullet-active');
                }else{
                  item.classList.remove('swiper-pagination-bullet-active');
                }
              });
            }
          },0)
        }
      }
    });
  }, [])
  return (
    <div className="swiper-container news1">
      <div className="swiper-wrapper">
        {
          gameCharacters.map((item,index) => <div className="swiper-slide" key={'slide2'+index} data-index={index}>
            <div className="info">
              <img src={item.url} alt="" onClick={()=>goToDetail(index)} />
              <div className="desc" onClick={()=>goToDetail(index)}>{t(item.desc)}</div>
              <div className="date" onClick={()=>goToDetail(index)}>{t(item.date)}</div>
            </div>
          </div>)
        }
      </div>
      <div className={`swiper-pagination ${'active'+activeIndex}`}></div>
    </div>
  )
}
